<template>
    <div class="condition">
        <common-table ref="table" :list="compareList" :cols="cols" @del="specDel">
            <template #drug>
                <el-select v-model="drug.model" value-key="comCode" :loading="drug.loading" placeholder="请选择药品" reserve-keyword
                           filterable remote :remote-method="drugSearch" @change="drugChange">
                    <el-option v-for="item in drug.list" :key="item.comCode" :label="item.comName" :value="item"></el-option>
                </el-select>
            </template>

            <template v-slot:slot_spec="{row}">
                <template v-if="row && row.comCode">{{row.spec}}</template>
                <el-select v-else v-model="spec.model" value-key="specCode" :loading="spec.loading" placeholder="请选择规格"
                           filterable remote :remote-method="specSearch" @change="specChange">
                    <el-option v-for="item in spec.list" :key="item.specCode" :label="item.spec" :value="item"></el-option>
                </el-select>
            </template>

            <template v-slot:slot_category="{row}">
                <p v-show="row.real.treatBig">大类：
                    <el-link type="primary" :underline="false" @click="goClassification(row.real,1)">{{row.real.treatBig}}</el-link>
                </p>
                <p v-show="row.real.treatSmall" >亚类：
                    <el-link type="primary" :underline="false" @click="goClassification(row.real,2)">{{row.real.treatSmall}}</el-link>
                </p>
                <p v-show="row.real.treatThree">三类：
                    <el-link type="primary" :underline="false" @click="goClassification(row.real,3)">{{row.real.treatThree}}</el-link>
                </p>
                <p v-show="row.real.treatFour">四类：
                    <el-link type="primary" :underline="false" @click="goClassification(row.real,4)">{{row.real.treatFour}}</el-link>
                </p>
            </template>
            <template v-slot:slot_five="{row}">
                <div style="display: flex;">
                    <div style="width: 50%;padding-right: 5px;color: #748de1;" 
                        v-if="row.real.categoryTopFiveCompetitorList && row.real.categoryTopFiveCompetitorList.length > 0" >
                        <p v-for="item in row.real.categoryTopFiveCompetitorList" :key="item.comCode" @click="clickIcon(row.real.categoryTopFiveCompetitorList,item)"
                        style="cursor: pointer;">
                            <i class="el-icon-plus"></i>
                            {{item.comName}}
                        </p>
                    </div>
                    <div style="width: 50%;padding-left: 5px;color: #748de1;" 
                        v-if="row.real.chemistryTopFiveCompetitorList && row.real.chemistryTopFiveCompetitorList.length > 0">
                        <p v-for="item in row.real.chemistryTopFiveCompetitorList" :key="item.comCode" @click="clickIcon(row.real.chemistryTopFiveCompetitorList,item)"
                        style="cursor: pointer;">
                            <i class="el-icon-plus"></i>
                            {{item.comName}}</p>
                    </div>
                </div>
            </template>
            <template v-slot:slot_lastYearSaleAmount="{row}">
                <el-link type="primary" :underline="false" @click="seeYearSale(row)">
                    {{row.real.lastYearSaleAmount || '--'}}{{row.real.lastYearSaleAmount ? $variable.unit : ''}}</el-link>
            </template>
            <template v-slot:slot_lastQuarterSaleAmount="{row}">
                <el-link type="primary" :underline="false" @click="seeQuarterSale(row)">
                    {{row.real.lastQuarterSaleAmount || '--'}}{{row.real.lastQuarterSaleAmount ? $variable.unit : ''}}</el-link>
            </template>
            <template v-slot:slot_calcPrice="{row}">
                <div class="flex flex-center">
                    <el-input class="my-input" v-model="row.real.calcPrice" placeholder="请输入"></el-input>
                    ~
                    <el-input class="my-input" v-model="row.real.calcPrice1" placeholder="请输入"></el-input>
                </div>
            </template>
            <template v-slot:slot_calcDay="{row}">
                <div class="flex flex-center">
                    <el-input class="my-input" v-model="row.real.calcDay" placeholder="请输入"></el-input>
                    ~
                    <el-input class="my-input" v-model="row.real.calcDay1" placeholder="请输入"></el-input>
                </div>
            </template>
            <template v-slot:slot_calcTotal="{row}">
                <div class="flex flex-center">
                    <el-input class="my-input" readonly :value="$help.accMul(row.real.calcDay || 0, row.real.calcPrice || 0)"></el-input>
                    ~
                    <el-input class="my-input" readonly :value="$help.accMul(row.real.calcDay1 || 0, row.real.calcPrice1 || 0)"></el-input>
                </div>
            </template>
            <template v-slot:slot_lastWinState="{row}">
                <div class="clickable" @click="goCountry(row)" style="color: #748de1;" v-if="row.real.lastWinState">
                    {{row.real.lastWinState || '--'}}
                    <i v-if="['暴涨', '涨价'].includes(row.real.lastWinState)" class="el-icon-top-right up"></i>
                    <i v-else-if="['暴跌', '跌价'].includes(row.real.lastWinState)" class="el-icon-bottom-right down"></i>
                </div>
                <div v-else>--</div>
            </template>
            <template v-slot:slot_component="{row}">
                <div style="display: inline-block;" v-for="(item,index) in row.real.component" :key="index">
                    <template v-if="typeof(item) === 'object'">
                        <multi-level-jump :obj="item" :btnList="componentList" :name="'component'"></multi-level-jump>
                    </template>
                    <span v-else>{{item}}</span>
                </div>
            </template>
            <template v-slot:slot_indication="{row}">
                <div style="display: inline-block;" v-for="(item,index) in row.real.indication" :key="index">
                    <template v-if="typeof(item) === 'object'">
                        <multi-level-jump :obj="item" :btnList="indicationList" :name="'indication'"></multi-level-jump>
                    </template>
                    <span v-else>{{item}}</span>
                </div>
            </template>
        </common-table>


        <ts-dialog :show.sync="canvasYear.show" :title="canvasYear.title" close-on-click-modal hide-footer size="50%">
            <div :id="canvasYear.id" class="dialog-canvas"></div>
        </ts-dialog>
    </div>
</template>
<script>
import variable from '@/util/variable'
import CommonTable from './CommonTable'
import searchJs from './searchJs'
export default {
    components: {CommonTable},
    props: {
        initDrugs: {
            type: Array,
            default: () => [],
        },
        visitId: {},
    },
    mixins: [searchJs],
    data() {
        return {
            drug: {
                model: {},
                list: [],
                loading: false,
            },
            spec: {
                model: {},
                list: [],
                loading: false,
            },
            canvasYear: {
                show: false,
                title: '',
                id: 'compareYear1',
                form: {},
            },
            compareList: [],
            cols: [
                {key: 'spec', value: '规格',listClass: 'tc',},
                {key: 'base', value: '基本参数', block: true},
                {key: 'drugsNature', value: '药品性质',listClass: 'tc',},
                {key: 'compName', value: '生产企业'},
                {key: 'compNumber', value: '生产企业数',listClass: 'tc',},
                {key: 'category', value: '药品分类（ATC）'},
                {key: 'five', value: '同类竞品前20名'},


                {key: 'base1', value: '药品性价比', block: true},
                {key: 'component', value: '成分'},
                {key: 'indication', value: '适应症'},
                {key: 'offIndication', value: '超适应症'},
                {key: 'usageDosage', value: '用法用量'},
                {key: 'adverseReactions', value: '不良反应'},
                {key: 'drugInteractions', value: '药物相互作用'},
                {key: 'contraindications', value: '禁忌'},
                {key: 'precautions', value: '注意事项'},
                {key: 'minUnitPrice', value: '制剂价格（区间）',listClass: 'tc', calc: true, event: this.goBid},
                {key: 'calcPrice', value: '日用金额【元/天】'},
                {key: 'calcDay', value: '疗程（天）'},
                {key: 'calcTotal', value: '疗程金额<br/>（元/疗程）'},
                {key: 'base2', value: '药品市场信息', block: true},


                {key: 'healthInsurance', value: '医保',listClass: 'tc',event: this.goHealth},
                {key: 'essentialDrugs', value: '基药',listClass: 'tc', event: this.goBase},
                {key: 'essentialDrugsSpec', value: '基药规格'},
                {key: 'protectedVarieties', value: '中药保护品种',listClass: 'tc',},
                {key: 'protectedVarietiesCompName', value: '中药保护申请企业'},
                {key: 'winProvinceNumber', value: '中标省份数',listClass: 'tc', event: this.goBid},
                {key: 'lastWinDate', value: '最新中标时间',listClass: 'tc',},
                {key: 'lastWinPrice', value: '最新中标价格（元）',listClass: 'tc', event: this.goBid},
                {key: 'maxWinPrice', value: '最高制剂价（元）',listClass: 'tc',},
                {key: 'maxWinProvince', value: '最高价省份',listClass: 'tc', event: this.goBid},
                {key: 'maxWinCompName', value: '最高价企业', event: this.goBid},
                {key: 'minWinPrice', value: '最低制剂价（元）',listClass: 'tc',},
                {key: 'minWinProvince', value: '最低价省份',listClass: 'tc', event: this.goBid},
                {key: 'minWinCompName', value: '最低价企业', event: this.goBid},
                {key: 'avgWinPrice', value: '平均制剂价（元）',listClass: 'tc',},
                {key: 'lastWinState', value: '最新中标状态',listClass: 'tc',},
                {key: 'base4', value: '国家集采信息', block: true},
                {key: 'nationalCentralized', value: '国家集采',listClass: 'tc', event: this.goNation},
                {key: 'purchaseCompName', value: '集采中标企业'},
                {key: 'packingSpecification', value: '包装规格'},
                {key: 'purchasePrice', value: '中选价格',listClass: 'tc',},
                {key: 'purchaseBatch', value: '中选批次',listClass: 'tc',},
                {key: 'base3', value: '药品市场销售', block: true},
                {key: 'lastYearSaleAmount', value: `最近一年销售额<br/>(${variable.lastYear.value})`,listClass: 'tc', suffix: variable.unit},
                {key: 'lastQuarterSaleAmount', value: `最近一年季度<br/>销售额(${variable.lastQuarter.value})`,listClass: 'tc', suffix: variable.unit},
            ],
        }
    },
    created() {
        this.drug.list = this.initDrugs
    },
    computed: {
        compareMap() {
            const map = {}
            this.compareList.forEach(item => {
                map[item.comCode + item.specCode] = true
            })

            return map
        },
    },
    methods: {
        clickIcon(list,row){
            console.log('点击图标',row);
            this.drug.model = row
            this.drug.list = list
            this.drugChange()
        },
        //pdf
        openPDF() {
            if (this.compareList.length > 0) {
                this.$help.exportInfo('确定导出吗', 'con/drugContrast/exportDrugSpecContrast', this.compareList.map(item => {
                    const calcPrice = item.real.calcPrice ? (isNaN(parseFloat(item.real.calcPrice)) ? 0 : parseFloat(item.real.calcPrice)) : 0
                    const calcPrice1 = item.real.calcPrice1 ? (isNaN(parseFloat(item.real.calcPrice1)) ? 0 : parseFloat(item.real.calcPrice1)) : 0
                    const calcDay = item.real.calcDay ? (isNaN(parseFloat(item.real.calcDay)) ? 0 : parseFloat(item.real.calcDay)) : 0
                    const calcDay1 = item.real.calcDay1 ? (isNaN(parseFloat(item.real.calcDay1)) ? 0 : parseFloat(item.real.calcDay1)) : 0
                    return {
                        comCode: item.comCode,
                        specCode: item.specCode,
                        dayPrice: `${calcPrice || '--'}~${calcPrice1 || '--'}`,
                        dayNumber: `${calcDay || '--'}~${calcDay1 || '--'}`,
                        dayAmount: `${this.$help.accMul(calcPrice, calcDay)}~${this.$help.accMul(calcPrice1, calcDay1)}`,
                    }
                }))
            } else {
                this.$store.commit('error', '请选择药品')
            }
        },

        drugSearch(keyword = '') {
            this.drug.loading = true
            this.$api.get('report/drugContrast/queryAimBaseDrugsListByKeyword', {
                keyword: keyword,
            })
                .then(res => {
                    this.drug.list = res.data
                })
                .finally(() => {
                    this.drug.loading = false
                })
        },
        drugChange() {
            this.spec.model = {}
            this.spec.list = []

            if (this.drug.model.comCode) {
                this.specSearch()
            }

            // 滚动条滚动到最上方
            this.$refs.table.$refs.panel.scrollTop = 0
        },

        specSearch(keyword = '') {
            if (!this.drug.model.comCode) {
                this.$store.commit('error', '请先选择药品')
                return false
            }
            this.spec.loading = true
            this.$api.get('con/drugContrast/querySpecListByKeyword', {
                keyword: keyword,
                comCode: this.drug.model.comCode,
            })
                .then(res => {
                    this.spec.list = res.data.map(item => {
                        item.comCode = this.drug.model.comCode
                        item.comName = this.drug.model.comName
                        return item
                    })
                })
                .finally(() => {
                    this.spec.loading = false
                })
        },
        specChange() {
            if (this.compareMap[this.spec.model.comCode + this.spec.model.specCode]) {
                this.$store.commit('error', '该规格已添加')
            } else {
                const model = this.$help.deepClone(this.spec.model)
                this.$set(model, 'comCode', this.drug.model.comCode)
                this.$set(model, 'comName', this.drug.model.comName)
                this.$set(model, 'id', `${model.comCode}${model.specCode}`)

                this.$set(model, 'loading', true)
                this.$set(model, 'real', {})
                this.$api.get('con/drugContrast/queryDrugSpecContrast', {
                    comCode: this.drug.model.comCode,
                    specCode: this.spec.model.specCode,
                }, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                    .then(res => {
                        if (res.data) {
                            res.data = this.setLink(res.data)
                        }
                        model.real = res.data || {}
                    })
                    .finally(() => {
                        this.$set(model, 'loading', false)
                    })
                this.compareList.push(model)
                this.finalChange()
            }
            this.drug.model = {}
            this.spec.model = {}
            this.spec.list = []
            this.drug.list = this.initDrugs
        },
        specDel(index) {
            this.compareList.splice(index, 1)
            this.finalChange()
        },
        finalChange() {
            this.$emit('change', this.compareList)
        },

        seeYearSale(data) {
            this.canvasYear.show = true
            this.canvasYear.form = data
            this.canvasYear.title = `${data.comName}历年销售趋势`

            this.$nextTick(() => {
                this.$help.renderBarOnly(this.canvasYear.id, Object.keys(data.real.saleTrend).map(key => {
                    return {
                        name: key,
                        value: data.real.saleTrend[key],
                    }
                }))
            })
        },
        goBid(data) {
            this.goPage('/store/mainf_drug_winning_informations', data)
        },

        goNation(data) {
            this.goPage('/store/dr_national_centralized_procurement', data)
        },

        // 跳往医保目录
        goHealth(data) {
            this.goPage('/store/du_health_insurance_directories', data, true, true)
        },

        // 跳往基药目录
        goBase(data) {
            this.goPage('/store/du_essential_drugs', data, true, true)
        },
        //跳转全国药品价格
        goCountry(data) {
            this.goPage('/bidding-info-country', data)
        },
        // 跳往按类别
        goClassification(data,val) {
            let arr = []
            if(val === 1){
                arr.push({
                    treatCategoryCode: data.treatBigCode,
                    treatCategoryName: data.treatBig,
                    id: data.treatBigId
                })
            }else if(val === 2){
                arr.push({
                    treatCategoryCode: data.treatBigCode,
                    treatCategoryName: data.treatBig,
                    id: data.treatBigId
                },{
                    treatCategoryCode: data.treatSmallCode,
                    treatCategoryName: data.treatSmall,
                    id: data.treatSmallId
                })
            }else if(val === 3){
                arr.push({
                    treatCategoryCode: data.treatBigCode,
                    treatCategoryName: data.treatBig,
                    id: data.treatBigId
                },{
                    treatCategoryCode: data.treatSmallCode,
                    treatCategoryName: data.treatSmall,
                    id: data.treatSmallId
                },{
                    treatCategoryCode: data.treatThreeCode,
                    treatCategoryName: data.treatThree,
                    id: data.treatThreeId
                })
            }else{
                arr.push({
                    treatCategoryCode: data.treatBigCode,
                    treatCategoryName: data.treatBig,
                    id: data.treatBigId
                },{
                    treatCategoryCode: data.treatSmallCode,
                    treatCategoryName: data.treatSmall,
                    id: data.treatSmallId
                },{
                    treatCategoryCode: data.treatThreeCode,
                    treatCategoryName: data.treatThree,
                    id: data.treatThreeId
                },{
                    treatCategoryCode: data.treatFourCode,
                    treatCategoryName: data.treatFour,
                    id: data.treatFourId
                })
            }
            const { href } = this.$router.resolve({
                path: '/new/aim_drugs_treat_big',
                query: {
                    odd:JSON.stringify(arr)
                }
            });
            window.open(href, "_blank");
        },

        goPage(url, data,hideSpec) {
            const { href } = this.$router.resolve({
                path: url,
                query: {
                    comCode: data.comCode,
                    unifySpecCode: hideSpec ? undefined : data.specCode,
                    // unifySpec: data.spec,
                }
            });
            window.open(href, "_blank");
        },
        seeQuarterSale(data) {
            this.canvasYear.show = true
            this.canvasYear.form = data
            this.canvasYear.title = '最新一年季度销售趋势'

            this.$nextTick(() => {
                this.$help.renderBarOnly(this.canvasYear.id, Object.keys(data.real.lastYearSaleTrend).map(key => {
                    const arr = key.split('q')
                    return {
                        name: `${arr[0]}年${arr[1]}季度`,
                        value: data.real.lastYearSaleTrend[key],
                    }
                }))
            })
        },
    }
}
</script>